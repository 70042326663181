<template>
  <section id="perfil-usuario">
    <b-col cols="14">
      <b-card>
        <div>
          <!-- Header: Personal Info -->
          <div class="d-flex my-1">
            <label class="font-weight-bolder size-label-title">Mi Perfil</label>
          </div>
          <b-row class="mb-1">
            <b-col md="3" cols="12" class="pr-0">
              <feather-icon
                icon="UserIcon"
                size="16"
                class="text-body align-middle"
              />
              <label class="font-weight-bolder ml-1">Nombre de Usuario</label>
            </b-col>
            <b-col md="9" cols="12">
              <label>{{ user.name }}</label>
            </b-col>
          </b-row>

          <b-row class="mb-1 align-items-center">
            <b-col md="3" cols="12" class="pr-0">
              <feather-icon
                icon="AtSignIcon"
                size="16"
                class="text-body align-middle"
              />
              <label class="font-weight-bolder ml-1">Email</label>
            </b-col>
            <b-col md="9" cols="12">
              <label>{{ user.email }}</label>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col md="3" cols="12" class="pr-0">
              <feather-icon
                icon="UsersIcon"
                size="16"
                class="text-body align-middle"
              />
              <label class="font-weight-bolder ml-1">Roles</label>
            </b-col>
            <b-col md="9" cols="12">
              <b-badge
                class="pill-style font-weight-normal"
                pill
                variant="info"
                >{{
                  user.role == 'ADMIN'
                    ? 'Administrador'
                    : user.role == 'BASIC'
                    ? 'Básico'
                    : 'Sólo lectura'
                }}</b-badge
              >
            </b-col>
          </b-row>

          <!-- Configuracion -->
          <div class="d-flex mt-3 mb-1">
            <label class="font-weight-bolder size-label-title"
              >Configuración</label
            >
          </div>
          <!--  <b-row class="mb-1">
            <b-col md="3" cols="12" class="pr-0">
              <feather-icon
                icon="ClockIcon"
                size="16"
                class="text-body align-middle"
              />
              <label class="font-weight-bolder ml-1">Zona horaria</label>
            </b-col>
            <b-col md="5" cols="12">
              <b-form-group>
                <v-select
                  v-model="user.timezone"
                  :dir="dir"
                  label="title"
                  :options="option"
                  class="select-size-sm"
                />
              </b-form-group>
            </b-col>
            <b-col cols="5"></b-col>
          </b-row> -->

          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="
              modalModificarContraseniaShow = !modalModificarContraseniaShow
            "
            v-b-modal.modal-modificar-contrasenia
          >
            Modificar contraseña
          </b-button>

          <!-- botones -->
          <div class="row align-items-center mt-5">
            <div class="col justify-content-end">
              <div style="display: flex">
                <div style="margin-left: auto">
                  <router-link to="/dashboard/ecommerce">
                    <b-button
                      id="cancel-button"
                      variant="outline-primary"
                      class="mr-1"
                    >
                      Cancelar
                    </b-button>
                  </router-link>

                  <b-button
                    id="accept-button"
                    variant="primary"
                    class="shadow-none"
                    @click="validateUserInfo()"
                  >
                    Guardar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-col>

    <b-modal
      id="modal-modificar-contrasenia"
      title="Modificar contraseña"
      ok-title="Agregar"
      ok-variant="primary shadow-none modal-button-size"
      cancel-title="Cancelar"
      cancel-variant="outline-primary shadow-none modal-button-size"
      hide-footer
      v-model="modalModificarContraseniaShow"
      centered
      size="sm"
      ref="modal-modificar-contrasenia"
    >
      <b-form>
        <b-form-group
          label="Contraseña actual"
          label-for="contraseniaActual"
          class="my-2"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              :type="password1FieldType"
              id="contraseniaActual"
              v-model="contraseniaActual"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="password1ToggleIcon"
                @click="togglePassword1Visibility"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="Contraseña nueva"
          label-for="contraseniaNueva"
          class="my-2"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              :type="password2FieldType"
              id="contraseniaNueva"
              v-model="contraseniaNueva"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="password2ToggleIcon"
                @click="togglePassword2Visibility"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="Repetir contraseña nueva"
          label-for="contraseniaRepetida"
          class="mt-2 mb-2"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              :type="password3FieldType"
              id="contraseniaRepetida"
              v-model="contraseniaRepetida"
            />

            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="password3ToggleIcon"
                @click="togglePassword3Visibility"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-invalid-feedback v-if="errorContrasenia" class="mb-2">
          La contraseña actual y nueva deben ser diferentes. La contraseña nueva
          y repetida deben coincidir.
        </b-form-invalid-feedback>

        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              class="mr-1 modal-button-size shadow-none"
              @click="
                () => {
                  hideErrorContrasenia();
                  hideModificarContraseniaModal();
                }
              "
            >
              Cancelar
            </b-button>
            <b-button
              variant="outline-primary"
              class="modal-button-size shadow-none"
              id="agregar-button-modal"
              @click="
                () => {
                  handleChangePassword();
                }
              "
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BInputGroupAppend,
  BButton,
  BCard,
  BBadge,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axios from 'axios';
import { getUserData } from '@/auth/utils';
import { backendUrl } from '@/config';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BInputGroup,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BInputGroupAppend,
    BButton,
    BCard,
    BBadge,
    ToastificationContent,
  },

  data() {
    return {
      dir: 'ltr',
      option: [
        'UTC-03 (SaoPaulo)',
        'UTC-03 (Santiago)',
        'UTC-05 (Bogota, Quito, Lima)',
        'UTC-06 (Ciudad de México)',
        'UTC-11 (Samoa)',
        'UTC-10 (Hawaii)',
        'UTC-09 (Anchorage, Juneau)',
        'UTC-08 (Seattle, San Francisco, Los Angeles)',
        'UTC-07 (Edmonton, Denver, Phoenix)',
        'UTC-06 (Winnipeg, Chicago, Houston, Mexico)',
        'UTC-06 (Tegucigalpa, Managua, San Jose)',
        'UTC-05 (New York, Miami, La Habana)',
        'UTC-05 (Bogota, Quito, Lima)',
        'UTC-05 (Puerto Principe, Panama)',
        'UTC-04 (Halifax, Santo Domingo, Caracas)',
        'UTC-04 (Georgetown, Manaus, La Paz)',
        'UTC-04 (Asuncion, Santiago de Chile)',
        'UTC-03 (Brasilia, Rio De Janeiro, Montevideo, Buenos Aires)',
        'UTC-02 (Recife)UTC-01 (Azores)',
        'UTC+00 (Londres, Dublín, Lisboa, Casablanca, Dakar, Accra)',
        'UTC+01 (Paris, Madrid, Roma, Berlín, Praga)',
        'UTC+01 (Belgrado, Varsovia, Estocolmo, Oslo)',
        'UTC+01 (Argel, Lagos, Brazzaville, Luanda)',
        'UTC+02 (Helsinki, Minks, Bucarest, Estambul)',
        'UTC+02 (Atenas, Beirut, Cairo, Tripoli, Harare, Ciudad del Cabo)',
        'UTC+03 (San Petersburgo, Moscow, Bagdad, Riad)',
        'UTC+03 (Addis Abeba, Kampala, Nairobi, Mogadisco)',
        'UTC+04 (Samara, Baku, Tbilisi, Dubai)',
        'UTC+05 (Sheliabinsk, Karachi, Islamabad)',
        'UTC+06 (Omsk, Tashkent, Dacca)',
        'UTC+07 (Novosibirsk, Bangkok, Hanoi, Yakarta)',
        'UTC+08 (Irkutsk, Lhasa, Beijing, Hong Kong)',
        'UTC+08 (Kuala Lumpur, Singapur, Manila, Perth)',
        'UTC+09 (Tokyo, Seul)',
        'UTC+10 (Vladivostok, Sydney, Melbourne)',
        'UTC+11 (Noumea, Magaban)',
        'UTC+12 (Wellington Nueva Zelanda)',
      ],
      modalModificarContraseniaShow: false,
      contraseniaActual: '',
      contraseniaNueva: '',
      contraseniaRepetida: '',
      errorContrasenia: false,
      password1FieldType: 'password',
      password2FieldType: 'password',
      password3FieldType: 'password',
      user: {},
    };
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    password3ToggleIcon() {
      return this.password3FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  mounted() {
    this.datosPerfil();
  },
  methods: {
    datosPerfil() {
      this.$store.commit('appConfig/changeLoading', true);
      const user = getUserData();
      this.user = user;
      this.$store.commit('appConfig/changeLoading', false);
    },
    async handleChangePassword() {
      if (this.contraseniaActual == '') {
        this.showErrorContraseniaVieja('danger');
      }
      if (this.contraseniaNueva !== this.contraseniaRepetida) {
        this.errorContrasenia = true;
        return;
      }

      this.$store.commit('appConfig/changeLoading', true);

      const token = localStorage.getItem('token');
      let storage = JSON.parse(localStorage.userData);
      let id = storage.idUser;

      var data = JSON.stringify({
        passOld: this.contraseniaActual,
        passNew: this.contraseniaNueva,
      });

      var config = {
        method: 'POST',
        url: `${backendUrl}/usuarios/changepassword/`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };
     
      try {
        let resp = await axios(config);
        if (resp.data.code == 1) {
          this.$store.commit('appConfig/changeLoading', false);

          this.showErrorCambioPassword('danger', resp.data.message);
        } else {
          this.$store.commit('appConfig/changeLoading', false);
          this.validateContrasenias();
        }
      } catch (error) {
        this.$store.commit("appConfig/changeLoading",false);
        this.errorContrasenia = true;

        return;
      }
    },
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === 'password' ? 'text' : 'password';
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === 'password' ? 'text' : 'password';
    },
    togglePassword3Visibility() {
      this.password3FieldType =
        this.password3FieldType === 'password' ? 'text' : 'password';
    },

    hideErrorContrasenia() {
      this.errorContrasenia = false;
    },

    reiniciarContrasenia() {
      this.contraseniaActual = '';
      this.contraseniaNueva = '';
      this.contraseniaRepetida = '';
    },

    hideModificarContraseniaModal() {
      this.$refs['modal-modificar-contrasenia'].hide();
    },
    showErrorContraseniaVieja(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Falta la contraseña Actual',
          text: 'Intente nuevamente',

          icon: 'XIcon',
          variant,
        },
      });
    },
    showErrorCambioPassword(variant, msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `${msg}`,
          text: 'Intente nuevamente',

          icon: 'XIcon',
          variant,
        },
      });
    },
    showContraseniaModificadaExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha modificado tu contraseña exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showPerfilDeUsuarioModificadoExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha modificado tu Perfil exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showPerfilDeUsuarioModificadoErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Modificar tu Perfil',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    validateUserInfo() {
      if (true) {
        this.showPerfilDeUsuarioModificadoExitosoToast('success');
        this.$router.push('/dashboard/ecommerce');
      } else {
        this.showPerfilDeUsuarioModificadoErrorToast('danger');
        this.$router.push('/dashboard/ecommerce');
      }
    },
    validateContrasenias() {
      if (
        this.contraseniaActual.length > 0 &&
        this.contraseniaNueva.length > 0 &&
        this.contraseniaRepetida.length > 0 &&
        this.contraseniaActual != this.contraseniaNueva &&
        this.contraseniaActual != this.contraseniaRepetida &&
        this.contraseniaNueva == this.contraseniaRepetida
      ) {
        this.hideModificarContraseniaModal();
        this.showContraseniaModificadaExitosoToast('success');
        this.hideErrorContrasenia();
        this.reiniciarContrasenia();
      } else {
        this.errorContrasenia = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#accept-button {
  width: 146.22px;
}

#cancel-button {
  width: 146.22px;
}

.modal-button-size {
  width: 173px;
}

.size-label-title {
  font-size: 13px;
}
</style>
